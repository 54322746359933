import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/core"
import logo_mleczarnia from "../../static/ref/mleczarnia.png"
import logo_ofop from "../../static/ref/ofop.png"
import logo_ofz from "../../static/ref/organic_farma_zdrowia.png"
import logo_pcpm from "../../static/ref/pcpm.png"
import logo_prasowy from "../../static/ref/prasowy.png"
import logo_chili from "../../static/ref/chili_models.png"
import logo_gdanski from "../../static/ref/gdanski.png"
import logo_protec from "../../static/ref/protec.png"
import logo_zandii from "../../static/ref/zandii.png"
import logo_punktal from "../../static/ref/punktal.png"
import logo_tkt from "../../static/ref/tkt.png"
import logo_ic from "../../static/ref/ic.png"
import logo_africa from "../../static/ref/africa.png"
import logo_wedel from "../../static/ref/wedel.png"
import logo_armfilm from "../../static/ref/armfilm.png"
import logo_tvn from "../../static/ref/tvn.png"
import logo_alfa from "../../static/ref/alfa.png"
import logo_chilimgmt from "../../static/ref/chili_mgmt.png"
import logo_krowa from "../../static/ref/rozowa_krowa.png"
import logo_acmarca from "../../static/ref/acmarca.png"

const style = css`
  padding-top: 20px;
  .refs {
    .ref {
      font-weight: 300;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 10px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      @media (max-width: 850px) {
        flex-wrap: wrap;
      }
    }
    .text {
      flex-basis: 75%;
      text-align: justify;
      padding-right: 20px;
      line-height: 1.3;
      font-size: 0.9rem;
      border-right: 1px solid #ccc;
      font-style: italic;
      @media (max-width: 850px) {
        flex-basis: 100%;
        border-right: 0px solid #ccc;
        padding-right: 0px;
      }

      color: #404040;
    }
    .author {
      flex-basis: 25%;
      display: block;
      font-weight: 400;
      padding-left: 15px;
      @media (max-width: 850px) {
        flex-basis: 100%;
        padding-left: 0px;
        margin-top: 5px;
      }
    }
    .position {
      display: block;
      font-weight: 300;
      font-size: 0.9rem;
      margin-top: 5px;
    }
  }
  .logos {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
      max-width: 15%;
      margin: 10px 22px;
      @media (max-width: 850px) {
        margin: 5px 10px;

        max-width: 25%;
      }
    }
  }
`

export default function Home() {
  return (
    <Layout>
      <section className="wrapper" css={style}>
        <h1>Referencje</h1>
        <div className="refs">
          <div className="ref">
            <p className="text">
              Z zespołem firmy Reklamizer współpracujemy od 2013 roku. Przez ten
              czas dali się poznać jako solidny partner. Oprócz druku typowych
              materiałów informacyjnych, zawsze możemy liczyć na propozycje
              niestandardowych rozwiązań, które następnie sprawdzają się w
              wymagających warunkach - szczególnie, jeżeli wysyłamy je na misje
              poza granicami kraju. Doceniamy również wysoką elastyczność form
              dostawy.
            </p>
            <p className="author">
              dr Wojtek Wilk
              <span className="position">
                Prezes Polskiego Centrum Pomocy Międzynarodowej
              </span>
            </p>
          </div>
          <div className="ref">
            <p className="text">
              Studio Reklamizer ma pod opieką naszą agencję od samego początku
              jej istnienia - od stworzenia strony internetowej, przez kompozyty
              modelek i modeli, po wizytówki czy naklejki. Chłopaki dbają o
              eleganckie, spójne graficznie projekty i wydruki wysokiej jakości.
              Z całego serca polecam.
            </p>
            <p className="author">
              Piotr Czaykowski
              <span className="position">Prezes Chili Models</span>
            </p>
          </div>
          <div className="ref">
            <p className="text">
              Zdecydowanie polecam usługi Reklamizera. Współpracujemy od lat, a
              sprawność ich działania najbardziej doceniamy podczas OFIPów -
              organizowanego co 3 lata największego forum NGOsów w Polsce. Przy
              organizowaniu wydarzeń tego formatu szczególnie ważna jest
              pewność, że wszystkie produkty - identyfikatory, rollupy, ścianki
              konferencyjne, flagi, stoiska piknikowe itp. będą wykonane
              solidnie i dostarczone na czas.
            </p>
            <p className="author">
              Weronika Czyżewska
              <span className="position">
                Wiceprezeska Ogólnopolskiej Federacji Organizacji Pozarządowych
              </span>
            </p>
          </div>
        </div>
        <div className="logos">
          <img src={logo_mleczarnia} alt="Mleczarnia Jerozolimska" />
          <img
            src={logo_ofop}
            alt="Ogólnopolska Federacja Organizacji Pozarządowych"
          />
          <img src={logo_ofz} alt="Organic Farma Zdrowia" />
          <img src={logo_pcpm} alt="Polskei Centrum Pomocy Międzynarodowej" />
          <img src={logo_tvn} alt="TVN" />

          <img src={logo_prasowy} alt="Prasowy Bar Mleczny Warszawa" />
          <img src={logo_chili} alt="Chili Models" />
          <img src={logo_protec} alt="PRO TEC CSS" />
          <img src={logo_gdanski} alt="Gdański Bar Mleczny" />
          <img src={logo_zandii} alt="ZANDII" />
          <img src={logo_punktal} alt="Punktal" />
          <img src={logo_tkt} alt="TKT Engineering" />
          <img src={logo_ic} alt="Incentive Care" />
          <img src={logo_africa} alt="Africa Line" />
          <img src={logo_wedel} alt="Pijalnie Czekolady Wedel" />
          <img src={logo_armfilm} alt="Armfilm" />
          <img src={logo_alfa} alt="Alfa" />
          <img src={logo_chilimgmt} alt="Chili Management" />
          <img src={logo_krowa} alt="Bar Mleczny Różowa Krowa" />
          <img src={logo_acmarca} alt="ac marca" />
        </div>
      </section>
    </Layout>
  )
}
